// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from "vue";
import { defineSliceZoneComponents } from "@prismicio/vue";

export const components = defineSliceZoneComponents({
  action: defineAsyncComponent(() => import("./Action/index.vue")),
  cards: defineAsyncComponent(() => import("./Cards/index.vue")),
  features: defineAsyncComponent(() => import("./Features/index.vue")),
  intro: defineAsyncComponent(() => import("./Intro/index.vue")),
  logos: defineAsyncComponent(() => import("./Logos/index.vue")),
  page_header: defineAsyncComponent(() => import("./PageHeader/index.vue")),
  pricing_packs: defineAsyncComponent(() => import("./PricingPacks/index.vue")),
  rich_text: defineAsyncComponent(() => import("./RichText/index.vue")),
  services: defineAsyncComponent(() => import("./Services/index.vue")),
  social_media_link: defineAsyncComponent(
    () => import("./SocialMediaLink/index.vue"),
  ),
  stats: defineAsyncComponent(() => import("./Stats/index.vue")),
  video: defineAsyncComponent(() => import("./Video/index.vue")),
});
